import { Box, Button } from '@mui/material'
import { spacing } from '@orus.eu/pharaoh'
import { createElement, useState, type ReactElement } from 'react'
import { assert } from '../../lib/errors'

export type EditorProps<T> = {
  value: T
  onChange: (newValue: T) => void
}

export type DraftEditorProps<T> = {
  value: T
  onApply: (newValue: T) => void
  editor: (props: EditorProps<T>) => ReactElement
}

type EditorState<T> = { type: 'sync' } | { type: 'editing'; value: T } | { type: 'saving'; value: T }

export function DraftEditor<T>(props: DraftEditorProps<T>): ReactElement {
  const [state, setState] = useState<EditorState<T>>({ type: 'sync' })

  const apply = () => {
    assert(state.type === 'editing', 'Cannot apply if not editing')
    const newValue = state.value
    setState({ type: 'saving', value: newValue })
    props.onApply(newValue)
  }

  const reset = () => {
    setState({ type: 'sync' })
  }

  const changeCurrentValue = (newValue: T) => {
    setState({ type: 'editing', value: newValue })
  }

  const currentValue = state.type === 'sync' ? props.value : state.value
  const hasChanges = state.type === 'editing'

  return (
    <form>
      {createElement<EditorProps<T>>(props.editor, {
        value: currentValue,
        onChange: changeCurrentValue,
      })}
      <Box sx={{ marginTop: spacing[70] }}>
        <Button variant="outlined" disabled={!hasChanges} onClick={() => reset()}>
          Reset
        </Button>
        <Button variant="contained" disabled={!hasChanges} onClick={() => apply()} sx={{ marginLeft: spacing[50] }}>
          Apply
        </Button>
      </Box>
    </form>
  )
}
