import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { TechnicalError } from '@orus.eu/error'
import {
  Avatar,
  Button,
  CheckboxContainer,
  ContentContainerBackoffice,
  PersistentNotification,
  ResponsiveCard,
  spacing,
  Text,
  TextFieldFormField,
  useAsyncCallback,
  useEnqueueToast,
} from '@orus.eu/pharaoh'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { isSuccess } from '@orus.eu/result'
import { memo, useCallback, useState, type ReactElement } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { useApi } from '../../../../lib/use-api/use-api'
import { BackofficeSubsectionTitle } from '../../../atoms/backoffice-subsection-title'
import { LocalLoadingState } from '../../../molecules/local-loading-state'
import { DraftEditor } from '../../../organisms/draft-editor'

export default function PlatformAdminPage(): ReactElement {
  const trigger500ErrorForSentryTest = useCallback(() => {
    trpc.configuration.trigger500ErrorForSentryTest.mutate().then(
      () => alert("Route didn't crash"),
      () => alert('Route crashed as expected'),
    )
  }, [])

  const navigateToCpms = useNavigateTo({ to: '/bak/admin/cpms' })
  const navigateToIma = useNavigateTo({ to: '/bak/admin/ima' })
  const navigateToJobs = useNavigateTo({ to: '/bak/admin/jobs' })

  return (
    <ContentContainerBackoffice marginTop={spacing[70]}>
      <Header title="Administration" />
      <PersistentNotification variant="danger">
        From this page, you can easily break things.
        <br />
        Be careful what you do here !
      </PersistentNotification>

      <BackofficeSubsectionTitle>Other admin pages</BackofficeSubsectionTitle>

      <div
        css={css`
          display: flex;
          gap: ${spacing[50]};
        `}
      >
        <Button variant="secondary" avatar={<Avatar icon="arrow-right-regular" />} onClick={navigateToCpms}>
          CPMS
        </Button>
        <Button variant="secondary" avatar={<Avatar icon="arrow-right-regular" />} onClick={navigateToIma}>
          IMA
        </Button>
        <Button variant="secondary" avatar={<Avatar icon="arrow-right-regular" />} onClick={navigateToJobs}>
          Bull MQ jobs
        </Button>
      </div>

      <BackofficeSubsectionTitle>Test tools</BackofficeSubsectionTitle>

      <div
        css={css`
          display: flex;
          gap: ${spacing[50]};
        `}
      >
        <Button
          variant="secondary"
          onClick={() => {
            alert(
              'An error will be thrown when you click ok, it should show in Sentry with "Test client error" message',
            )
            // throw in a timeout to prevent react dev tools from catching the error
            setTimeout(() => {
              throw new TechnicalError('This is a test problem')
            })
          }}
        >
          Trigger client error
        </Button>
        <Button variant="secondary" onClick={trigger500ErrorForSentryTest}>
          Trigger server error
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            trpc.dev.crash.mutate().then(
              () => alert("Procedure didn't crash"),
              () => alert('Procedure crashed as expected'),
            )
          }}
        >
          Trigger tRPC error
        </Button>
      </div>

      <BackofficeSubsectionTitle>Persisted views status</BackofficeSubsectionTitle>
      <PersistedViewsStatusCard />

      <BackofficeSubsectionTitle>Consumers states</BackofficeSubsectionTitle>
      <StoreConsumerInfoCard />

      <BackofficeSubsectionTitle>Company name autocomplete</BackofficeSubsectionTitle>

      <AdminPageStyledCard>
        <Text>
          By default, the company autocomplete can only find results by siren and siret.
          <br />
          To make name-based autocomplete work, a dedicated mongo url with read rights on the{' '}
          <code>place_autocomplete</code> collection with an Atlas Search index called <code>autocomplete</code>.<br />
          This allows testing the on test environments with production-like data without loading the full insee database
          and indexing it.
          <br />
        </Text>
        <AutocompleteAdminEditor />
      </AdminPageStyledCard>

      <BackofficeSubsectionTitle>Wakam Subscription Key</BackofficeSubsectionTitle>

      <AdminPageStyledCard>
        <Text>
          This key allows calling the Wakam API. Keys can be obtained from{' '}
          <Link href="https://developer.wakam.com/profile" target="_blank" rel="noreferrer">
            our wakam profile page
          </Link>{' '}
          and paste here.
        </Text>
        <WakamSubscriptionKeyEditor />
      </AdminPageStyledCard>

      <BackofficeSubsectionTitle>Singing token JWT Key</BackofficeSubsectionTitle>

      <AdminPageStyledCard>
        <Text>This key is used to sign token used during the subscription&apos;s sign page</Text>
        <SubscriptionSignatureTokenJWTKeyEditor />
      </AdminPageStyledCard>

      <BackofficeSubsectionTitle>INSEE database synchronization</BackofficeSubsectionTitle>

      <AdminPageStyledCard>
        <Text>
          When the insee database synchronization is enabled, a background task will keep at least the last 30 days of
          the insee database synchronized.
          <br />
        </Text>
        <InseeSyncAdminEditor />
      </AdminPageStyledCard>

      <BackofficeSubsectionTitle>Retry signature handling</BackofficeSubsectionTitle>

      <AdminPageStyledCard>
        <SignatureHandlingTool />
      </AdminPageStyledCard>
    </ContentContainerBackoffice>
  )
}

const AdminPageStyledCard = styled(ResponsiveCard)`
  padding: 40px;
  display: 'flex';
  flex-direction: 'column';
  gap: ${spacing[70]};
`

function WakamSubscriptionKeyEditor(): ReactElement {
  const wakamSubscriptionKey = trpcReact.configuration.getWakamSubscriptionKey.useQuery()

  const onApply = useAsyncCallback(async (value: string) => {
    const newValue = value || null
    await trpc.configuration.setWakamSubscriptionKey.mutate(newValue ?? undefined)
    document.location.reload()
  }, [])

  const testConfiguration = useAsyncCallback(async () => {
    try {
      await trpc.configuration.testWakamConfiguration.query()
      alert('Wakam call successful, check network tab and server logs for details')
    } catch (_err) {
      alert('Wakam call failed, check logs for details')
    }
  }, [])

  if (wakamSubscriptionKey.isLoading) {
    return <LocalLoadingState>Loading...</LocalLoadingState>
  }

  return (
    <>
      <DraftEditor
        value={wakamSubscriptionKey.data ?? ''}
        onApply={onApply}
        editor={(editorProps) => (
          <OutlinedInput
            fullWidth
            value={editorProps.value}
            onChange={(event) => editorProps.onChange(event.target.value)}
          />
        )}
      />
      <Text element="p">
        You can test the saved configuration by clicking{' '}
        <Button variant="text" onClick={testConfiguration}>
          here.
        </Button>
      </Text>
    </>
  )
}

function SubscriptionSignatureTokenJWTKeyEditor(): ReactElement {
  const subscriptionSignatureTokenJwtSecret = trpcReact.configuration.getTokenJwtSecret.useQuery()

  const onApply = useAsyncCallback(async (value: string) => {
    if (!value) {
      alert('Empty values not allowed here')
      return
    }
    await trpc.configuration.setTokenJwtSecret.mutate(value)
    document.location.reload()
  }, [])

  if (subscriptionSignatureTokenJwtSecret.isLoading) {
    return <LocalLoadingState>Loading...</LocalLoadingState>
  }

  return (
    <DraftEditor
      value={subscriptionSignatureTokenJwtSecret.data ?? ''}
      onApply={onApply}
      editor={(editorProps) => (
        <OutlinedInput
          fullWidth
          value={editorProps.value}
          onChange={(event) => editorProps.onChange(event.target.value)}
        />
      )}
    />
  )
}

function AutocompleteAdminEditor(): ReactElement {
  const readonlyAutocompleteMongoUrl = trpcReact.configuration.getPlaceAutocompleteMongoUrl.useQuery()

  const onApply = useAsyncCallback(async (value: string) => {
    const newValue = value || null
    await trpc.configuration.setPlaceAutocompleteMongoUrl.mutate(newValue ?? undefined)
    document.location.reload()
  }, [])

  if (readonlyAutocompleteMongoUrl.isLoading) {
    return <LocalLoadingState>Loading...</LocalLoadingState>
  }

  return (
    <DraftEditor
      value={readonlyAutocompleteMongoUrl.data ?? ''}
      onApply={onApply}
      editor={(editorProps) => (
        <OutlinedInput
          fullWidth
          value={editorProps.value}
          onChange={(event) => editorProps.onChange(event.target.value)}
        />
      )}
    />
  )
}

function InseeSyncAdminEditor(): ReactElement {
  const inseeSyncSetting = trpcReact.configuration.isInseeSyncEnabled.useQuery()

  const onApply = useAsyncCallback(async (newValue: boolean) => {
    await trpc.configuration.setInseeSyncEnabled.mutate(newValue)
    document.location.reload()
  }, [])

  if (inseeSyncSetting.isLoading) {
    return <LocalLoadingState>Loading...</LocalLoadingState>
  }

  return (
    <DraftEditor
      value={!!inseeSyncSetting.data}
      onApply={onApply}
      editor={(editorProps) => (
        <CheckboxContainer checked={editorProps.value} onChange={editorProps.onChange}>
          <Text>Keep the INSEE database synchronized in background</Text>
        </CheckboxContainer>
      )}
    />
  )
}

const PersistedViewsStatusCard = memo(function PersistedViewsStatusCard(): ReactElement {
  const [persistedViewsStatuses] = trpcReact.platformStatus.getPersistedViewsStatus.useSuspenseQuery()

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Remaining events to process</TableCell>
            <TableCell>Current minor version</TableCell>
            <TableCell>Minor version migration status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {persistedViewsStatuses.map((persistedViewsStatus) => {
            return (
              <TableRow key={persistedViewsStatus.name}>
                <TableCell>{persistedViewsStatus.name}</TableCell>
                <TableCell>{persistedViewsStatus.currentBacklog}</TableCell>
                <TableCell>{persistedViewsStatus.minorVersion}</TableCell>
                <TableCell>{persistedViewsStatus.minorVersionMigrationDone ? '✅ Done' : '⏳ In progress'}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
})

function StoreConsumerInfoCard(): ReactElement {
  const apiCallState = useApi(getConsumers)
  if (!apiCallState.ready) {
    return <LocalLoadingState />
  }
  const { outOfDateConsumers, upToDateConsumers, removedWorkersConsumers } = apiCallState.data

  return (
    <TableContainer>
      <Table>
        <ConsumersTableSection name="Out of date consumers" consumers={outOfDateConsumers} />
        <ConsumersTableSection name="Uptodate consumers" consumers={upToDateConsumers} />
        <ConsumersTableSection name="Removed workers consumers" consumers={removedWorkersConsumers} />
      </Table>
    </TableContainer>
  )
}

type StoreConsumerInfo = Awaited<ReturnType<(typeof trpc)['platformStatus']['listConsumerInfo']['query']>>[number]

async function getConsumers(): Promise<{
  outOfDateConsumers: StoreConsumerInfo[]
  upToDateConsumers: StoreConsumerInfo[]
  removedWorkersConsumers: StoreConsumerInfo[]
}> {
  const consumers = await trpc.platformStatus.listConsumerInfo.query()

  // sort in place
  consumers.sort((consumerInfo1, consumerInfo2) => {
    if (consumerInfo1.consumer < consumerInfo2.consumer) {
      return -1
    } else if (consumerInfo1.consumer > consumerInfo2.consumer) {
      return 1
    } else {
      return 0
    }
  })

  return {
    outOfDateConsumers: consumers.filter((consumer) => consumer.runData && consumer.runData.backlog > 0),
    upToDateConsumers: consumers.filter((consumer) => consumer.runData?.backlog === 0),
    removedWorkersConsumers: consumers.filter((consumer) => !consumer.runData),
  }
}

const ConsumersTableSection = memo<{ name: string; consumers: StoreConsumerInfo[] }>(function ConsumersTableSection({
  name,
  consumers,
}) {
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell colSpan={4}>
            <Text variant="h6">{name}</Text>
          </TableCell>
        </TableRow>
        {consumers.length === 0 ? (
          <TableRow>
            <TableCell>
              <Text variant="subtitle">None</Text>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
        {consumers.length > 0 ? (
          <TableRow>
            <TableCell>
              <Text variant="subtitle">Consumer name</Text>
            </TableCell>
            <TableCell>
              <Text variant="subtitle">Consumed store</Text>
            </TableCell>
            <TableCell>
              <Text variant="subtitle">
                Backlog<Text variant="caption">(items)</Text>
              </Text>
            </TableCell>
            <TableCell>
              <Text variant="subtitle">
                Lag<Text variant="caption">(seconds)</Text>
              </Text>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
      </TableHead>
      <TableBody>
        {consumers.map((storeConsumerInfo) => {
          return (
            <TableRow key={storeConsumerInfo.consumer}>
              <TableCell>{storeConsumerInfo.consumer}</TableCell>
              <TableCell>{storeConsumerInfo.store}</TableCell>
              <>
                {storeConsumerInfo.runData ? (
                  <>
                    <TableCell>{storeConsumerInfo.runData.backlog}</TableCell>
                    <TableCell>{(storeConsumerInfo.runData.lag / 1000).toFixed(0)}</TableCell>
                  </>
                ) : (
                  <TableCell colSpan={2}>Removed worker</TableCell>
                )}
              </>
            </TableRow>
          )
        })}
      </TableBody>
    </>
  )
})

const SignatureHandlingTool = memo(function SignatureHandlingTool() {
  const { enqueueToast } = useEnqueueToast()
  const [subscriptionId, setSubscriptionId] = useState('')
  const [signatureId, setSignatureId] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const retrySignatureHandling = useAsyncCallback(async () => {
    setIsLoading(true)
    try {
      const result = await trpc.contracts.retrySignatureHandling.mutate({ subscriptionId, signatureId })
      if (isSuccess(result)) {
        enqueueToast('Replay successful !', { variant: 'success' })
        return
      }
      switch (result.problem.type) {
        case 'signature-event-not-found':
          enqueueToast('Event not found, check inputs', { variant: 'danger' })
          return
      }
      enqueueToast('Unknown error', { variant: 'danger' })
      return
    } catch (_err) {
      enqueueToast('Failed, check sentry', { variant: 'danger' })
    } finally {
      setIsLoading(false)
    }
  }, [subscriptionId, signatureId, enqueueToast])

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        gap: ${spacing[50]};
      `}
    >
      <Text variant="body1">Replay everything that happens when handling a signature event</Text>
      <div
        css={css`
          display: flex;
          gap: ${spacing[50]};
          justify-content: start;
        `}
      >
        <TextFieldFormField
          label="Subscription ID"
          size="small"
          value={subscriptionId}
          onChange={(event) => setSubscriptionId(event.target.value)}
        />
        <TextFieldFormField
          label="Signature ID"
          size="small"
          value={signatureId}
          onChange={(event) => setSignatureId(event.target.value)}
        />
      </div>
      <Button
        disabled={isLoading || !subscriptionId || !signatureId}
        isLoading={isLoading}
        onClick={retrySignatureHandling}
      >
        Submit
      </Button>
    </div>
  )
})
